<template>
  <div
    id="printMe"
    class="
      geplante-bewegungen
      mb-12
      border-b-2 border-gray-300
      pb-8
      print print-bg-red
    "
  >
    <div
      class="flex justify-center items-center text-center col-span-4 space-x-2"
    >
      <button @click="previousYear">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6 text-secondary"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M15 19l-7-7 7-7"
          />
        </svg>
      </button>
      <datepicker
        style="cursor: pointer !important; width: 5rem"
        class="
          border-none
          text-2xl text-center
          date-input
          bg-secondary
          text-white
          rounded-md
          py-0
        "
        :placeholder="'Month'"
        :inputFormat="'yyyy'"
        :minimumView="'year'"
        :maximumView="'year'"
        :initialView="'year'"
        v-model="planningDate"
      ></datepicker>
      <button @click="nextYear">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6 text-secondary"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9 5l7 7-7 7"
          />
        </svg>
      </button>
    </div>

    <section class="gp-bewegungen">
      <div
        class="
          main-action
          gap-2
          text-sm text-secondary
          rounded
          mt-5
          md:flex
          justify-between
        "
      >
        <div class="add print-display">
          <button
            type="button"
            @click="openModal({}, 'new')"
            title="neue Bewegungen erstellen"
            class="
              shadow-lg
              flex
              items-center
              text-lg
              border border-secondary
              py-1
              px-2
              rounded-lg
              font-light
              hover:text-white hover:bg-secondary hover:border-primary
            "
          >
            <span>{{ $t("New planning") }}</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
              />
            </svg>
          </button>
        </div>
      </div>
      <div class="overflow-x-auto">
        <div class="gp-bewegungen-top-row sm:w-full">
          <div
            class="
              text-xs
              justify-between
              text-secondary
              mb-4
              mt-4
              rounded
              border-2 border-secondary
              px-5
              py-2
              grid grid-cols-2
              sm:grid-cols-5
              print-flex print-flex-row
            "
          >
            <div class="gp-bew-cat font-semibold print-w-4 print-text-lg">
              <span>{{ $t("Category") }}</span>
            </div>
            <!-- <div class="gp-bew-cat text-center">
              <span>+/- </span>
            </div> -->
            <div
              class="
                gp-bew-betrag
                text-right
                font-semibold
                print-w-2 print-text-lg
              "
            >
              <span>{{ $t("amount in €") }}</span>
            </div>
            <div
              class="
                gp-bew-pers
                hidden
                md:block
                text-center
                font-semibold
                print-w-2 print-text-lg
              "
            >
              <span>{{ $t("Person") }}</span>
            </div>
            <div
              class="
                gp-bew-pers
                hidden
                md:block
                text-center
                font-semibold
                print-w-2 print-text-lg
              "
            >
              <span>{{ $t("Account") }}</span>
            </div>
            <div
              class="
                gp-bew-pers
                hidden
                md:block
                text-center
                font-semibold
                print-w-2 print-text-lg
              "
            >
              <span>{{ $t("Due Date") }}</span>
            </div>
            <!-- <div class="gp-bew-show text-right print-display">
              <span>{{ $t("action") }}</span>
            </div> -->
          </div>
        </div>
      </div>

      <div v-if="Object.keys(planningList).length">
        <div v-if="planningList.income">
          <span class="font-bold print-w-full print-font-bold">{{
            $t("income")
          }}</span>
          <div class="overflow-x-auto">
            <div
              v-for="(category, index) in Object.keys(planningList.income)"
              :key="index"
            >
              <div
                class="
                  items-center
                  grid
                  gap-2
                  bg-secondary
                  text-white
                  rounded-md
                  my-px
                  py-1
                  px-5
                  grid-cols-2
                  sm:grid-cols-5 sm:w-full
                  print-flex print-flex-row
                "
              >
                <div class="items-center print-w-4">
                  <span class="font-semibold text-sm">{{ $t(category) }}</span>
                </div>
                <div class="items-center justify-end flex text-right print-w-2">
                  <span class="text-right text-sm font-semibold">
                    {{
                      planningList.income[category].total.toLocaleString(
                        "de-DE",
                        { minimumFractionDigits: 2 }
                      )
                    }}</span
                  >
                </div>
              </div>
              <div
                v-for="(item, idx) in planningList.income[category].items"
                :key="idx"
                class="sm:w-full"
              >
                <!-- {{item}} -->
                <div
                  class="bewegungen-hit items-center"
                  role="option"
                  aria-selected="false"
                >
                  <div
                    class="
                      bewegungen-inner
                      items-center
                      grid grid-cols-2
                      sm:grid-cols-5
                      gap-2
                      cursor-pointer
                      print-flex print-flex-row
                    "
                    @click="openModal(item, 'update')"
                  >
                    <div class="gp-bew-cat print-w-4">
                      <span> {{ $t(item.category.name) }} </span>
                    </div>

                    <div class="gp-bew-pers text-right print-w-2">
                      <!-- <span>{{ parseFloat(item.amount).toFixed(2) }} </span> -->
                      <span
                        class="font-semibold"
                        :class="
                          item.category
                            ? item.category.cat_in_out
                              ? 'text-green-500'
                              : 'text-red-500'
                            : ''
                        "
                        >{{
                          item.amount.toLocaleString("de-DE", {
                            minimumFractionDigits: 2,
                          })
                        }}</span
                      >
                    </div>
                    <div
                      class="
                        gp-bew-pers
                        text-center
                        capitalize
                        hidden
                        sm:block
                        print-w-2
                      "
                    >
                      <span>
                        {{ item.person ? item.person.name : $t("together") }}
                      </span>
                    </div>
                    <div
                      class="
                        gp-bew-pers
                        text-center
                        capitalize
                        hidden
                        sm:block
                        print-w-2
                      "
                    >
                      <span>
                        {{ item.account ? $t(item.account.name) : "-" }}
                      </span>
                    </div>
                    <div
                      class="
                        gp-bew-pers
                        text-center text-xs
                        hidden
                        sm:block
                        print-w-2
                      "
                    >
                      <span> {{ dueDate(item) }}</span>
                    </div>
                    <!-- <div class="ef-bew-action flex justify-end print-display">
                      <div class="edit">
                        <button>
                          <PencilAltIcon
                            class="h-6 w-6 text-fuchsia-600"
                            @click="openModal(item, 'update')"
                          />
                        </button>
                      </div>
                      <div class="delete">
                        <button>
                          <TrashIcon
                            class="h-6 w-6 text-red-400"
                            @click="deletePlan(item.id)"
                          />
                        </button>
                      </div>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
            <div
              class="
                bewegungen-inner
                items-center
                grid grid-cols-2
                sm:grid-cols-5
                gap-2
                bg-gray-100
                sm:w-full
                print-flex print-flex-row
              "
            >
              <div class="gp-bew-cat col-span-1 font-semibold print-w-4">
                <span> {{ $t("Sum of the annual income") }} </span>
              </div>
              <div class="gp-bew-betrag text-right font-semibold print-w-2">
                <span class="text-green-500">
                  <!-- {{ sum(planningList.income[category].total) }} -->
                  {{
                    sum(planningList.income).toLocaleString("de-DE", {
                      minimumFractionDigits: 2,
                    })
                  }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div v-if="planningList.expenses">
          <span class="font-bold print-w-full print-font-bold">{{
            $t("expenses")
          }}</span>

          <div class="overflow-x-auto">
            <div
              v-for="(category, index) in Object.keys(planningList.expenses)"
              :key="index"
              class="sm:w-full"
            >
              <div
                class="
                  items-center
                  grid grid-cols-2
                  sm:grid-cols-5
                  gap-2
                  bg-secondary
                  text-white
                  rounded-md
                  my-px
                  py-1
                  px-5
                  print-flex print-flex-row
                "
              >
                <div class="items-center print-w-4">
                  <span class="font-semibold text-sm">{{ $t(category) }}</span>
                </div>
                <div class="items-center justify-end flex print-w-2">
                  <span class="font-semibold text-sm text-right"
                    >-{{
                      planningList.expenses[category].total.toLocaleString(
                        "de-DE",
                        { minimumFractionDigits: 2 }
                      )
                    }}</span
                  >
                </div>
              </div>

              <div
                v-for="(item, idx) in planningList.expenses[category].items"
                :key="idx"
              >
                <!-- {{item}} -->
                <div
                  class="bewegungen-hit items-center"
                  role="option"
                  aria-selected="false"
                >
                  <div
                    @click="openModal(item, 'update')"
                    class="
                      bewegungen-inner
                      items-center
                      grid grid-cols-2
                      sm:grid-cols-5
                      gap-2
                      cursor-pointer
                      print-flex print-flex-row
                    "
                  >
                    <div class="gp-bew-cat print-w-4">
                      <span> {{ $t(item.category.name) }} </span>
                    </div>
                    <div
                      class="
                        gp-bew-pers
                        text-right text-red-500
                        font-semibold
                        print-w-2
                      "
                    >
                      <span
                        >-{{
                          item.amount.toLocaleString("de-DE", {
                            minimumFractionDigits: 2,
                          })
                        }}
                      </span>
                    </div>
                    <div
                      class="
                        gp-bew-pers
                        text-center
                        capitalize
                        hidden
                        sm:block
                        print-w-2
                      "
                    >
                      <span>
                        {{ item.person ? item.person.name : $t("together") }}
                      </span>
                    </div>
                    <div
                      class="
                        gp-bew-pers
                        text-center
                        capitalize
                        hidden
                        sm:block
                        print-w-2
                      "
                    >
                      <span>
                        {{ item.account ? item.account.name : "-" }}
                      </span>
                    </div>
                    <div
                      class="
                        gp-bew-pers
                        text-center text-xs
                        hidden
                        sm:block
                        print-w-2
                      "
                    >
                      <span> {{ dueDate(item) }}</span>
                    </div>
                    <!-- <div class="ef-bew-action flex justify-end print-display">
                      <div class="edit">
                        <button>
                          <PencilAltIcon
                            class="h-6 w-6 text-fuchsia-600"
                            @click="openModal(item, 'update')"
                          />
                        </button>
                      </div>
                      <div class="delete">
                        <button>
                          <TrashIcon
                            class="h-6 w-6 text-red-400"
                            @click="deletePlan(item.id)"
                          />
                        </button>
                      </div>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
            <div
              class="
                bewegungen-inner
                items-center
                grid grid-cols-2
                sm:grid-cols-5
                gap-2
                bg-gray-100
                sm:w-full
                print-flex print-flex-row
              "
            >
              <div class="gp-bew-cat col-span-1 font-semibold print-w-4">
                <span> {{ $t("Sum of annual expenses") }} </span>
              </div>
              <div
                class="
                  gp-bew-betrag
                  text-right
                  font-semibold
                  text-red-500
                  print-w-2
                "
              >
                <span>
                  <!-- {{ sum(planningList.income[category].total) }} -->
                  -{{ sum(planningList.expenses) }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="overflow-x-auto">
          <div v-if="planningList.save">
            <span class="font-bold print-w-full print-font-bold">{{
              $t("saves")
            }}</span>
            <div
              v-for="(category, index) in Object.keys(planningList.save)"
              :key="index"
              class="sm:w-full"
            >
              <div
                class="
                  items-center
                  grid grid-cols-2
                  sm:grid-cols-5
                  gap-2
                  bg-secondary
                  text-white
                  rounded-md
                  my-px
                  py-1
                  px-5
                  print-flex print-flex-row
                "
              >
                <div class="items-center print-w-4">
                  <span class="font-semibold text-sm">{{ $t(category) }}</span>
                </div>
                <!-- <div class="gp-bew-betrag text-center">
                  <span class="flex justify-center"> - </span>
                </div> -->
                <div class="items-center justify-end flex print-w-2">
                  <span class="font-semibold text-sm text-right">{{
                    planningList.save[category].total.toLocaleString("de-DE", {
                      minimumFractionDigits: 2,
                    })
                  }}</span>
                </div>
              </div>

              <div
                v-for="(item, idx) in planningList.save[category].items"
                :key="idx"
              >
                <!-- {{item}} -->
                <div
                  class="bewegungen-hit items-center"
                  role="option"
                  aria-selected="false"
                >
                  <div
                    @click="openModal(item, 'update')"
                    class="
                      bewegungen-inner
                      items-center
                      grid grid-cols-2
                      sm:grid-cols-5
                      gap-2
                      cursor-pointer
                      print-flex print-flex-row
                    "
                  >
                    <div class="gp-bew-cat print-w-4">
                      <span> {{ $t(item.category.name) }} </span>
                    </div>
                    <!-- <div class="gp-bew-betrag text-center">
                      <span>
                        {{
                          item.category
                            ? item.category.cat_in_out
                              ? "+"
                              : "-"
                            : ""
                        }}
                      </span>
                    </div> -->
                    <div class="gp-bew-pers text-right font-semibold print-w-2">
                      <span
                        >{{
                          item.amount.toLocaleString("de-DE", {
                            minimumFractionDigits: 2,
                          })
                        }}
                      </span>
                    </div>
                    <div
                      class="
                        gp-bew-pers
                        text-center
                        capitalize
                        hidden
                        sm:block
                        print-w-2
                      "
                    >
                      <span>
                        {{ item.person ? item.person.name : $t("together") }}
                      </span>
                    </div>
                    <div
                      class="
                        gp-bew-pers
                        text-center
                        capitalize
                        hidden
                        sm:block
                        print-w-2
                      "
                    >
                      <span>
                        {{ item.account ? item.account.name : "-" }}
                      </span>
                    </div>
                    <div
                      class="
                        gp-bew-pers
                        text-center text-xs
                        hidden
                        sm:block
                        print-w-2
                      "
                    >
                      <span> {{ dueDate(item) }}</span>
                    </div>
                    <!-- <div class="ef-bew-action flex justify-end print-display">
                      <div class="edit">
                        <button>
                          <PencilAltIcon
                            class="h-6 w-6 text-fuchsia-600"
                            @click="openModal(item, 'update')"
                          />
                        </button>
                      </div>
                      <div class="delete">
                        <button>
                          <TrashIcon
                            class="h-6 w-6 text-red-400"
                            @click="deletePlan(item.id)"
                          />
                        </button>
                      </div>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
            <div
              class="
                bewegungen-inner
                items-center
                grid grid-cols-2
                sm:grid-cols-5
                gap-2
                bg-gray-100
                sm:w-full
                print-flex print-flex-row
              "
            >
              <div class="gp-bew-cat col-span-1 font-semibold print-w-4">
                <span> {{ $t("Sum of annual saves") }} </span>
              </div>
              <div class="gp-bew-betrag text-right font-semibold print-w-2">
                <span class="text-right">
                  {{
                    sum(planningList.save).toLocaleString("de-DE", {
                      minimumFractionDigits: 2,
                    })
                  }}
                </span>
              </div>
            </div>
          </div>
          <div class="mt-10">
            <div
              class="
                bewegungen-inner
                items-center
                grid grid-cols-2
                sm:grid-cols-5
                gap-2
                bg-gray-100
                sm:w-full
                print-flex print-flex-row
              "
            >
              <div class="gp-bew-cat col-span-1 font-semibold print-w-4">
                <span> {{ $t("Annual budget for variable expenses") }} </span>
              </div>
              <div class="gp-bew-betrag text-right font-semibold">
                <span>
                  {{
                    sumTotal(planningList, "year").toLocaleString("de-DE", {
                      minimumFractionDigits: 2,
                    })
                  }}
                </span>
              </div>
            </div>
          </div>
          <div class="mt-1">
            <div
              class="
                bewegungen-inner
                items-center
                grid grid-cols-2
                sm:grid-cols-5
                gap-2
                bg-gray-100
                sm:w-full
                print-flex print-flex-row
              "
            >
              <div class="gp-bew-cat col-span-1 font-semibold print-w-4">
                <span> {{ $t("Monthly budget for variable expenses") }} </span>
              </div>
              <div class="gp-bew-betrag text-right font-semibold print-w-2">
                <span>
                  {{
                    sumTotal(planningList, "month").toLocaleString("de-DE", {
                      minimumFractionDigits: 2,
                    })
                  }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <modal
    :open="showModal"
    @close="close"
    :type="type"
    :data="planning"
    :mode="mode"
  ></modal>
</template>

<script>
import Datepicker from "../../node_modules/vue3-datepicker";

import Modal from "@/components/Modal";
// import { PencilAltIcon, TrashIcon } from "@heroicons/vue/outline";
const monthList = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export default {
  components: { Modal, Datepicker },
  data() {
    return {
      showModal: false,
      type: null,
      planning: null,
      mode: null,
      monthList,
      sumIncome: 0,
      sumExpense: 0,
      sumSave: "0",
      planningDate: new Date(),
    };
  },
  watch: {
    planningDate() {
      this.$store.commit("setMainDate", this.planningDate);
      this.getPlanningList();
    },
  },
  computed: {
    planningList() {
      return this.$store.state.planning.planningList;
    },
    month() {
      return this.planningDate.getMonth() + 1;
    },
    year() {
      return this.planningDate.getFullYear();
    },
    mainDate() {
      return this.$store.state.mainDate;
    },
  },
  methods: {
    openModal(planning, mode) {
      this.$store.dispatch("User/GetCategory").then(() => {
        this.$store.dispatch("User/getPersons").then(() => {
          this.$store.dispatch("accounts/getAccountList" ,{count:100}).then(() => {
            this.showModal = true;
            this.planning = planning;
            this.mode = mode;
            this.type = "PlanningModal";
          });
        });
      });
    },
    getPlanningList() {
      this.$store.dispatch("planning/getPlanningList", {
        start: `${this.planningDate.getFullYear()}-01-01`,
        end: `${this.planningDate.getFullYear()}-12-31`,
      });
    },
    deletePlan(id) {
      this.$store.dispatch("planning/DeletePlan", {
        id,
        currentDate: {
          start: `${this.planningDate.getFullYear()}-01-01`,
          end: `${this.planningDate.getFullYear()}-12-31`,
        },
      });
    },
    dueDate(plan) {
      if (plan.repetition == "0") {
        const indexDate = +plan.date.slice(5, 7);
        return monthList[indexDate - 1];
      }
      if (plan.repetition == "m" && plan.repetition_n !== 0) {
        const endDateYear = plan.repetition_e.slice(0, 4);
        const endDateMonth = plan.repetition_e.slice(5, 7);
        const period = plan.repetition_f > 1 ? plan.repetition_f : "";
        const details = `${this.$t("All")} ${period} ${this.$t(
          "Months to"
        )} ${endDateMonth}-${endDateYear}`;
        return details;
      }
      if (plan.repetition == "y" && !plan.repetition_n == 0) {
        const endDate = plan.repetition_e.slice(0, 4);
        const period = plan.repetition_f > 1 ? plan.repetition_f : "";
        const details = `${this.$t("All")} ${period} ${this.$t(
          "Years to"
        )} ${endDate}`;
        return details;
      }
      if (plan.repetition == "m" && plan.repetition_n == 0) {
        const period = plan.repetition_f > 1 ? plan.repetition_f : "";
        const details = `${this.$t("All")} ${period} ${this.$t("months")} `;
        return details;
      }
      if (plan.repetition == "y" && plan.repetition_n == 0) {
        const period = plan.repetition_f > 1 ? plan.repetition_f : "";
        const details = `${this.$t("All")} ${period} Jahre `;
        return details;
      }
    },
    sum(plan) {
      const total = Object.keys(plan).reduce((t, i) => t + plan[i].total, 0);
      return total.toLocaleString("de-DE", { minimumFractionDigits: 2 });
    },
    sumTotal(plan, type) {
      let income = 0;
      let expenses = 0;
      let save = 0;

      if (plan.income) {
        income = Object.keys(plan.income).reduce(
          (t, i) => t + plan.income[i].total,
          0
        );
      }
      if (plan.expenses) {
        expenses = Object.keys(plan.expenses).reduce(
          (t, i) => t + plan.expenses[i].total,
          0
        );
      }
      if (plan.save) {
        save = Object.keys(plan.save).reduce(
          (t, i) => t + plan.save[i].total,
          0
        );
      }
      if (type == "year") {
        return income - expenses - save;
      }
      if (type == "month") {
        // const currentMonth = 12 - new Date().getMonth();
        return (income - expenses - save) / 12;
      }
    },
    close() {
      this.showModal = false;
    },
    nextYear() {
      this.planningDate = new Date(this.planningDate.getFullYear() + 1, 11, 31);
    },
    previousYear() {
      this.planningDate = new Date(this.planningDate.getFullYear() - 1, 11, 31);
    },
  },
  mounted() {
    this.getPlanningList();
    this.$store.commit("setMainDate", new Date());
  },
};
</script>

<style>
@media print {
  .print-display {
    display: none !important;
  }
  .print {
    display: flex !important;
    flex-direction: column !important;
  }
  .table {
    width: 100% !important;
  }
}
</style>
